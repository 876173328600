.Dynamic_table {
  th {
    @apply font-bold;
  }

  th,
  td {
    border: 1px solid rgb(224, 224, 224);
  }
}
