.textInput {
  @apply flex flex-col space-y-1 mb-4;

  &_label {
    @apply text-sm font-medium text-gray-700;
  }

  &_input {
    @apply flex bg-white py-2 px-4 border border-solid border-[#C3CCDD] text-xl rounded-lg;
    foo: bar;

    & > input {
      @apply flex-1 bg-transparent border-none focus:outline-none max-w-full text-base;
    }
    &_disabled {
      @apply flex bg-gray-200 py-2 px-4 border border-solid border-[#C3CCDD] text-xl rounded-lg;
      foo: bar;
      & > input {
        @apply bg-transparent;
      }
    }
  }
}
