.search {
  @apply mx-auto z-10;
  &_container {
    @apply flex bg-white rounded border border-slate-200;
    label {
      top: -3px;
    }
    input {
      @apply rounded;
      height: 36px;
      font-size: 15px;
    }
    svg {
      font-size: 19px;
    }
  }

  .resultsList {
    @apply absolute max-h-52 overflow-y-auto bg-white z-10 w-full cursor-pointer;
  }
}
