.footer {
  @apply bottom-0 w-full flex flex-wrap bg-slate-100 p-5 text-xs;
  strong {
    @apply text-sm mb-1 block;
  }

  &_item {
    @apply lg:w-6/12 mb-5;
    &:last-child {
      @apply w-full;
    }
  }
}
