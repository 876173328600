.filters {
  @apply p-4 bg-[#f0f0f0] mb-4;
  foo: bar;

  h2 {
    @apply text-lg mb-2;
  }

  &_wrapp {
    @apply flex flex-wrap md:flex-nowrap align-middle justify-between;
  }

  &_btn {
    a {
      @apply p-4 bg-blue-500 text-white text-sm block rounded-s-3xl rounded-e-3xl mt-2 md:mt-0;
    }
  }

  &_input {
    @apply mt-0 w-full md:w-1/2 bg-white border-0 rounded-lg;
    fieldset {
      @apply border-0;
    }
  }
}
