.mapActions {
  &_header {
    @apply relative lg:left-3 z-50 top-[1px] lg:end-[93%] w-max rounded flex items-center;
    foo: bar;

    &_openDrawer {
      @apply bg-white rounded-sm text-sm flex;
    }

    &_print {
      @apply bg-white rounded-sm text-sm p-[3px];
      foo: bar;
    }
  }
}

.drawerConfigs {
  &_container {
    @apply px-6;

    &_close {
      @apply absolute end-4 top-3;
    }

    &_actions {
      @apply flex items-center mb-3;

      svg {
        @apply mr-3;
      }

      span {
        @apply text-base;
      }

      &_title {
        @apply mb-3 text-lg;

        hr {
          @apply mt-3;
        }
      }
    }

    &_accordion {
      @apply p-0 shadow-none;
    }
  }
}

.mapHeader {
  @apply absolute z-10 w-full flex flex-wrap p-1 pl-3 bg-white items-center justify-center lg:justify-normal;

  &_logo {
    @apply mr-2;

    img {
      width: 123px;
      height: fit-content;
    }
  }
}

.drawerContainer {
  button {
    @apply justify-normal normal-case text-inherit mb-2 rounded-none;
  }
}
