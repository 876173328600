.login {
  @apply lg:flex flex-wrap lg:flex-nowrap;
}

.login_banner {
  @apply w-full min-h-screen relative pt-10 px-5 lg:pt-0 lg:px-0 hidden lg:flex justify-center items-center;

  &_mask {
    @apply opacity-80 h-full w-full z-0 absolute;
  }

  &_text {
    @apply text-white relative;
    h1 {
      @apply text-5xl font-bold;
    }
    p {
      @apply text-2xl;
    }
  }
}

.login_identify {
  @apply lg:min-h-[100vh] lg:pt-28 overflow-hidden;
  foo: bar;

  &_container {
    @apply p-10 lg:min-h-[70vh];
    foo: bar;

    &_logo {
      @apply flex items-center text-lg mb-14;
      p {
        @apply ml-5;
      }
      img {
        @apply mx-auto;
      }
    }

    &_form {
      @apply lg:w-[70%] mx-auto;
      foo: bar;
    }

    &_info {
      @apply flex items-center justify-center text-center mt-16 mb-8;
      h3 {
        @apply text-black;
      }
    }
  }
}
