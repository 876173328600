@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: "Montserrat", sans-serif;
}

body,
html,
#root,
.App,
main {
  min-height: 100vh;
}

@media(max-width: 992px) {
  main {
    margin-left: 0px !important;
    width: 100% !important;
    transition: none !important;
  }
}

.ol-zoom {
  top: 4.5em;
}

.ol-zoomslider {
  top: 8em;
}

.ol-attribution {
  right: 1em;
  bottom: 2rem;
}