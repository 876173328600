.menu {
  &_close {
    @apply mt-4 ms-2;
    svg {
      @apply me-2 text-white;
    }
  }

  &_link {
    @apply w-full p-2 px-4 flex items-center ms-2 mt-4 text-sm;
    svg {
      @apply me-2 text-lime-500 text-[20px];
      foo: bar;
    }
    &_active {
      @apply bg-white text-black relative;
    }
  }
}
