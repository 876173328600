.header {
  @apply shadow-inherit;

  &_btnOpen {
    &:hover {
      @apply bg-green-400 text-black;
    }
  }

  .header_user {
    @apply absolute right-14 top-0;
  }
}
