.form {
  @apply p-5 bg-gray-50 lg:w-3/6 lg:mx-auto;
  &_grup {
    @apply mb-2 w-full;
    &_wrapp {
      @apply flex items-center space-x-8 flex-wrap lg:flex-nowrap;
    }
    &_label {
      @apply text-gray-700 text-base font-bold w-full block;
    }
    &_input {
      @apply border-2 border-gray-300 w-full h-8;
    }
  }
}
.form_buttons {
  @apply flex justify-between space-x-5 my-5 pt-4 items-center border-t-2;
}
